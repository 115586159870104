import {
  BOOLEAN,
  DATE,
  DECIMAL,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from './dataTypes';

export const VALID_TYPES_TO_CONVERT = [
  TEXT,
  INTEGER,
  DECIMAL,
  SINGLE_OPTION,
  MULTIPLE_OPTION,
  // FILE,
];

export const VALID_FORMULA_TYPES_TO_CONVERT = [TEXT, INTEGER, DECIMAL];

export const VALID_FORMULA_OUTPUT_TYPES_TO_CONVERT = [
  BOOLEAN,
  DECIMAL,
  DATE,
  INTEGER,
  TEXT,
];
