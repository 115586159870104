import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { HelpTooltip, Label, SelectInput, Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { Aggregation } from '@noloco/core/src/constants/aggregationTypes';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  DataFieldType,
  INTEGER,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { validAggregationsForFieldType } from '@noloco/core/src/utils/aggregationDataTypes';
import usePrevious from '@noloco/core/src/utils/hooks/usePrevious';
import { getText } from '@noloco/core/src/utils/lang';
import {
  isMultiRelationship,
  isReverseMultiRelationship,
} from '@noloco/core/src/utils/relationships';
import DataField from './DataField';

const LANG_KEY = 'data.rollups';

type RollupEditorProps = {
  aggregation?: Aggregation | null;
  dataType: DataType;
  dataTypes: DataTypes;
  onValidChange?: (isValid: boolean) => void;
  readOnly?: boolean;
  rollupField?: string | null;
  rollupRelatedField?: string | null;
  setAggregation?: (aggregation: Aggregation) => void;
  setRollupField?: (rollupField: string) => void;
  setRollupRelatedField?: (rollupRelatedField: string) => void;
  surface: Surface;
};

const VALID_ROLLUP_TYPES = [TEXT, BOOLEAN, DECIMAL, INTEGER, DURATION, DATE];

const RollupEditor = ({
  aggregation,
  dataType,
  dataTypes,
  onValidChange,
  readOnly,
  rollupField,
  rollupRelatedField,
  setAggregation,
  setRollupField,
  setRollupRelatedField,
  surface,
}: RollupEditorProps) => {
  const rollupDataType = useMemo(() => {
    if (!rollupRelatedField) {
      return null;
    }

    const field = dataType.fields.getByName(rollupRelatedField);

    return field && dataTypes.getByName(field.type);
  }, [dataType, dataTypes, rollupRelatedField]);

  const rollupFieldOptions = useMemo(
    () =>
      rollupDataType
        ? rollupDataType.fields
            .filter(
              (field) =>
                !field.relationship &&
                VALID_ROLLUP_TYPES.includes(field.type) &&
                !field.hidden,
            )
            .map((field) => ({
              label: (
                <DataField
                  dataTypes={dataTypes}
                  field={field}
                  key={field.name}
                />
              ),

              value: field.name,
            }))
        : [],

    [dataTypes, rollupDataType],
  );
  const rollupRelatedFieldOptions = useMemo(
    () =>
      dataType.fields
        .filter(
          (field) =>
            !field.hidden &&
            field.type !== FILE &&
            (isMultiRelationship(field.relationship) ||
              (field.relatedField &&
                isReverseMultiRelationship(field.relatedField.relationship))),
        )
        .map((field) => ({
          label: (
            <DataField dataTypes={dataTypes} field={field} key={field.name} />
          ),

          value: field.name,
        })),

    [dataType.fields, dataTypes],
  );

  const agggregationOptions = useMemo(() => {
    if (!rollupDataType || !rollupField) {
      return [];
    }

    const field = rollupDataType.fields.getByName(rollupField);
    if (!field) {
      return [];
    }

    return validAggregationsForFieldType(field.type as DataFieldType).map(
      (agg) => ({
        value: agg,
        label: getText(LANG_KEY, 'aggregation.options', agg, 'label'),
        help: getText(LANG_KEY, 'aggregation.options', agg, 'help'),
      }),
    );
  }, [rollupDataType, rollupField]);

  const isValid = useMemo(
    () =>
      !!rollupDataType &&
      rollupFieldOptions.some(
        (rollupOption) => rollupOption.value === rollupField,
      ) &&
      !!aggregation &&
      agggregationOptions.some((agg) => agg.value === aggregation),
    [
      agggregationOptions,
      aggregation,
      rollupDataType,
      rollupField,
      rollupFieldOptions,
    ],
  );

  const previousIsValid = usePrevious(isValid);

  useEffect(() => {
    if (isValid !== previousIsValid && onValidChange) {
      onValidChange(isValid);
    }
  }, [isValid, onValidChange, previousIsValid]);

  return (
    <>
      <div
        className="mb-2 mt-4 flex items-center"
        data-testid="field-rollup-editor"
      >
        <Label surface={surface} m={0}>
          {getText(LANG_KEY, 'relatedField.label')}
        </Label>
        <HelpTooltip
          className={classNames('ml-2 hover:text-gray-500', {
            'text-gray-800': surface === LIGHT,
            'text-gray-200': surface === DARK,
          })}
          placement="right"
        >
          <p>{getText(LANG_KEY, 'relatedField.tooltip')}</p>
        </HelpTooltip>
      </div>
      <SelectInput
        className="mb-2"
        contained={true}
        data-testid="field-rollup-source-field"
        options={rollupRelatedFieldOptions}
        disabled={readOnly}
        placeholder={getText(LANG_KEY, 'relatedField.placeholder')}
        onChange={setRollupRelatedField}
        value={rollupRelatedField}
        surface={surface}
      />
      {rollupRelatedField && rollupDataType && (
        <>
          <div className="mb-2 mt-4 flex items-center">
            <Label surface={surface} m={0}>
              {getText(
                { dataType: rollupDataType.display },
                LANG_KEY,
                'relatedField.label',
              )}
            </Label>
            <HelpTooltip
              className={classNames('ml-2 hover:text-gray-500', {
                'text-gray-800': surface === LIGHT,
                'text-gray-200': surface === DARK,
              })}
              placement="right"
            >
              <p>
                {getText(
                  { dataType: rollupDataType.display },
                  LANG_KEY,
                  'relatedField.tooltip',
                )}
              </p>
            </HelpTooltip>
          </div>
          <SelectInput
            className="mb-2"
            contained={true}
            data-testid="field-rollup-target-field"
            options={rollupFieldOptions}
            placeholder={getText(
              { dataType: rollupDataType.display },
              LANG_KEY,
              'relatedField.placeholder',
            )}
            onChange={setRollupField}
            disabled={readOnly}
            surface={surface}
            value={rollupField}
          />
        </>
      )}
      {rollupField && rollupDataType && (
        <>
          <div className="mb-2 mt-4 flex items-center">
            <Label surface={surface} m={0}>
              {getText(
                { dataType: rollupDataType.display },
                LANG_KEY,
                'aggregation.label',
              )}
            </Label>
            <HelpTooltip
              className={classNames('ml-2 hover:text-gray-500', {
                'text-gray-800': surface === LIGHT,
                'text-gray-200': surface === DARK,
              })}
              placement="right"
            >
              <p>{getText(LANG_KEY, 'aggregation.tooltip')}</p>
            </HelpTooltip>
          </div>
          <SelectInput
            className="mb-2"
            contained={true}
            data-testid="field-rollup-aggregation"
            options={agggregationOptions}
            placeholder={getText(
              { dataType: rollupDataType.display },
              LANG_KEY,
              'aggregation.placeholder',
            )}
            disabled={readOnly}
            onChange={setAggregation}
            surface={surface}
            value={aggregation}
          />
        </>
      )}
    </>
  );
};

export default RollupEditor;
