import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { SelectInput, Switch, TextInput } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import BuildModeCustomFiltersEditor from '@noloco/core/src/components/buildMode/filters/BuildModeCustomFiltersEditor';
import IconEditor from '@noloco/core/src/components/editor/IconEditor';
import {
  DATE,
  DECIMAL,
  INTEGER,
  OBJECT,
  SINGLE_OPTION,
} from '@noloco/core/src/constants/dataTypes';
import { HintFeedback } from '@noloco/core/src/constants/hints';
import { BETWEEN, EQUAL } from '@noloco/core/src/constants/operators';
import PRIMITIVE_DATA_TYPES from '@noloco/core/src/constants/primitiveDataTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { DataList, FilterField } from '@noloco/core/src/models/View';
import { getDataTypeByName } from '@noloco/core/src/utils/data';
import { filterValidFilterFields } from '@noloco/core/src/utils/filters';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionType } from '@noloco/core/src/utils/options';
import {
  getDataCollectionOptionsOfType,
  getDataTypeOptionsOfTypes,
} from '@noloco/core/src/utils/renderedOptions';
import { isSearchAllowed } from '@noloco/core/src/utils/search';
import { withNullOption } from '@noloco/core/src/utils/settings';
import { DataItemOption } from '@noloco/core/src/utils/state';
import { UPDATE_DEBOUNCE_MS } from '../../../utils/hooks/projectHooks';
import Hint from '../../Hint';
import ConditionValueEditor from '../../canvas/ConditionValueEditor';
import { DataTypeValue } from '../../canvas/DataTypeInput';
import StringPropEditor from '../../canvas/StringPropEditor';
import CollectionSortInput from './CollectionSortInput';
import FieldConditionsEditor from './FieldConditionsEditor';
import FieldOptionsEditor from './FieldOptionsEditor';
import FieldsListEditor from './FieldsListEditor';

const NUMERIC_DATATYPES = [INTEGER, DECIMAL];

const nullScopeOptions = [{ value: undefined, label: '---' }];

const LANG_KEY = 'elements.VIEW';

const getFilterOperatorsForField = (fieldType: any) => {
  if (NUMERIC_DATATYPES.includes(fieldType)) {
    return [
      { value: EQUAL, label: getText(LANG_KEY, 'filters.equal') },
      { value: BETWEEN, label: getText(LANG_KEY, 'filters.between') },
    ];
  }

  return [];
};

type Props = {
  dataList: DataList;
  dataType: DataType;
  debouncedUpdateProperty: (path: ElementPath, value: any) => void;
  elementId?: string;
  elementPath: ElementPath;
  filters: FilterField[];
  hint: string | null;
  isRecordView: boolean;
  onHintFeedback: (feedback: HintFeedback) => Promise<void>;
  updateProperty: (path: ElementPath, value: any) => void;
  project: Project;
  searchEnabled: boolean | undefined;
  searchPlaceholder: string | undefined;
  sticky?: boolean;
};

const ViewCollectionFilterFieldsEditor = ({
  dataList,
  dataType,
  debouncedUpdateProperty,
  elementPath,
  filters,
  hint,
  isRecordView,
  onHintFeedback,
  updateProperty,
  project,
  searchEnabled,
  sticky = false,
  searchPlaceholder,
}: Props) => {
  const searchIsAllowed = useMemo(() => isSearchAllowed(dataList), [dataList]);

  const onFiltersChange = useCallback(
    (path, value) => {
      updateProperty(['filters', ...path], value);
    },
    [updateProperty],
  );

  const conditionsOptions = useMemo(
    () =>
      getDataTypeOptionsOfTypes(project, elementPath, PRIMITIVE_DATA_TYPES, {
        includeUniqueColumnar: true,
      }).filter(
        (option: DataItemOption) =>
          get(option, ['options', 0, 'value', 'id']) !== 'values',
      ),
    [project, elementPath],
  );

  return (
    <>
      <div className="mt-2 flex flex-col space-y-4 p-2">
        <div className="flex items-center justify-between">
          <BuildModeLabel>
            {getText(LANG_KEY, 'filters.searchFilter.label')}
          </BuildModeLabel>
          <Switch
            disabled={!searchIsAllowed}
            onChange={(value: boolean) =>
              updateProperty(['search', 'enabled'], value)
            }
            size={SM}
            value={searchIsAllowed ? searchEnabled : false}
          />
        </div>
        <BuildModeLabel>
          {getText(LANG_KEY, 'filters.searchFilter.help')}
        </BuildModeLabel>
        {searchEnabled && searchIsAllowed && (
          <TextInput
            debounceMs={UPDATE_DEBOUNCE_MS}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              debouncedUpdateProperty(
                ['search', 'placeholder'],
                event.target.value,
              )
            }
            value={searchPlaceholder}
            placeholder={getText(LANG_KEY, 'filters.searchFilter.placeholder')}
          />
        )}
        {!searchIsAllowed && (
          <div className="w-full rounded-lg bg-teal-200 p-2 text-xs font-medium text-teal-800">
            {getText(LANG_KEY, 'filters.searchFilter.disabled')}
          </div>
        )}
        <BuildModeLabel>
          {getText(LANG_KEY, 'filters.description')}
        </BuildModeLabel>
        <Hint className="mb-6 mt-4" hint={hint} vote={onHintFeedback} />
      </div>
      <FieldsListEditor
        allowChildFieldsFilter={({ type }: DataField) => type === OBJECT}
        dataType={dataType}
        dataTypes={project.dataTypes}
        value={filters}
        fields={dataType.fields}
        filter={filterValidFilterFields}
        onFieldsChange={onFiltersChange}
        getNewFieldConfig={(field: any) => ({
          placeholder: getText(
            { field: upperFirst(field.display) },
            LANG_KEY,
            'filters.placeholder',
          ),
          ...(NUMERIC_DATATYPES.includes(field.type)
            ? {
                minPlaceholder: getText(
                  { field: upperFirst(field.display) },
                  LANG_KEY,
                  'filters.min',
                ),
                maxPlaceholder: getText(
                  { field: upperFirst(field.display) },
                  LANG_KEY,
                  'filters.max',
                ),
              }
            : {}),
        })}
        sticky={sticky}
        permissionType="read"
      >
        {({ config, field, index, updateFields }: any) => {
          const isRelationship = !!field.relationship || !!field.relatedField;

          const usesBetweenOperator = get(config, 'filterOperator') === BETWEEN;

          const showSetDefaultFilterValue =
            field.type !== DATE && !usesBetweenOperator;

          const showSelectMultipleOption =
            isRelationship || field.type === SINGLE_OPTION;

          const options = isRelationship
            ? getDataCollectionOptionsOfType(
                project,
                elementPath,
                field.type,
                [],
                { collections: !isRecordView },
              )
            : [];
          const fieldDataType =
            isRelationship && getDataTypeByName(project.dataTypes, field.type);

          return (
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col space-y-2">
                <BuildModeSwitchSection
                  label={getText(LANG_KEY, 'fields.label')}
                  onChange={(value: any) =>
                    updateFields([index, 'label', 'hidden'], !value)
                  }
                  value={!get(config, 'label.hidden')}
                />
                <TextInput
                  debounceMs={UPDATE_DEBOUNCE_MS}
                  onChange={({ target: { value } }: any) => {
                    updateFields([index, 'label', 'value'], value);
                  }}
                  disabled={config.hidden}
                  value={get(config, 'label.value')}
                  placeholder={field.display}
                />
              </div>
              <BuildModeInput label={getText(LANG_KEY, 'filters.icon')}>
                <IconEditor
                  clearable={true}
                  elementProps={get(config, 'icon')}
                  inline={false}
                  placement="bottom"
                  surface={DARK}
                  updateProperty={(_: ElementPath, iconName: string | null) =>
                    updateFields([index, 'icon', 'name'], iconName)
                  }
                />
              </BuildModeInput>
              <BuildModeInput
                label={getText(LANG_KEY, 'filters.placeholderLabel')}
              >
                <TextInput
                  debounceMs={UPDATE_DEBOUNCE_MS}
                  onChange={({ target: { value } }: any) =>
                    updateFields([index, 'placeholder'], value)
                  }
                  value={get(config, 'placeholder')}
                  placeholder={getText(
                    { field: upperFirst(field.display) },
                    LANG_KEY,
                    'filters.placeholder',
                  )}
                />
              </BuildModeInput>
              <BuildModeInput label={getText(LANG_KEY, 'filters.helpText')}>
                <StringPropEditor
                  // @ts-expect-error TS(2322): Type '{ additionalScopeItems: { label: string; opt... Remove this comment to see the full error message
                  additionalScopeItems={nullScopeOptions}
                  contained={true}
                  elementPath={elementPath}
                  multiLine={true}
                  onChange={(value: any) => {
                    updateFields([index, 'helpText'], value);
                  }}
                  placeholder=""
                  project={project}
                  value={get(config, 'helpText')}
                />
              </BuildModeInput>
              {showSetDefaultFilterValue && (
                <BuildModeInput
                  label={getText(LANG_KEY, 'filters.defaultValueLabel')}
                >
                  <ConditionValueEditor
                    additionalScopeItems={nullScopeOptions}
                    contained={true}
                    dataType={dataType}
                    field={field}
                    project={project}
                    onChange={(value: any) =>
                      updateFields([index, 'defaultValue'], value)
                    }
                    includeUniqueColumnar={false}
                    value={get(config, 'defaultValue', [])}
                    elementPath={elementPath}
                    placeholder=""
                  />
                </BuildModeInput>
              )}
              {NUMERIC_DATATYPES.includes(field.type) && (
                <BuildModeInput
                  label={getText(LANG_KEY, 'filters.filterOperator')}
                >
                  <SelectInput
                    className="w-full text-black"
                    contained={true}
                    value={get(config, 'filterOperator', EQUAL)}
                    onChange={(option: any) =>
                      updateFields([index, 'filterOperator'], option)
                    }
                    options={getFilterOperatorsForField(field.type)}
                    size="sm"
                  />
                </BuildModeInput>
              )}
              {showSelectMultipleOption && (
                <BuildModeSwitchSection
                  label={getText(LANG_KEY, 'fields.multiple')}
                  onChange={(value: any) =>
                    updateFields([index, 'multiple'], value)
                  }
                  value={get(config, 'multiple')}
                />
              )}
              {isRelationship && (
                <>
                  <BuildModeInput
                    label={getText(LANG_KEY, 'new.optionsFilter')}
                  >
                    <div
                      id={`new-field-${field.name}-${dataType.name}`}
                      className="w-full"
                    >
                      <SelectInput
                        Button={DataTypeValue}
                        className="w-full text-black"
                        contained={true}
                        value={get(config, 'filter')}
                        options={withNullOption(options, false)}
                        onChange={(option: any) =>
                          updateFields([index, 'filter'], option)
                        }
                        usePortal={false}
                      />
                    </div>
                  </BuildModeInput>
                  {fieldDataType && (
                    <BuildModeCustomFiltersEditor
                      customFilters={get(config, 'customFilters', [])}
                      elementPath={elementPath}
                      onUpdateCustomFilters={(nextCustomFilters: any) =>
                        updateFields(
                          [index, 'customFilters'],
                          nextCustomFilters,
                        )
                      }
                      project={project}
                      selectedDataType={fieldDataType}
                    />
                  )}
                  {fieldDataType && (
                    <BuildModeInput label={getText('elements.LIST.sort')}>
                      <CollectionSortInput
                        dataType={fieldDataType}
                        value={get(config, 'orderBy')}
                        onChange={(newOrderBy: any) =>
                          updateFields([index, 'orderBy'], newOrderBy)
                        }
                      />
                    </BuildModeInput>
                  )}
                </>
              )}
              {isOptionType(field.type) && (
                <FieldOptionsEditor
                  field={field}
                  onChange={(path: any, value: any) => {
                    updateFields([index, 'optionsConfig', ...path], value);
                  }}
                  values={config.optionsConfig}
                />
              )}
              <FieldConditionsEditor
                conditionFieldOptions={conditionsOptions}
                dataType={dataType}
                elementPath={elementPath}
                onChange={(path: any, value: any) =>
                  updateFields([index, 'conditions', ...path], value)
                }
                project={project}
                value={get(config, 'conditions')}
              />
            </div>
          );
        }}
      </FieldsListEditor>
    </>
  );
};

export default ViewCollectionFilterFieldsEditor;
