import { DARK } from '@noloco/components/src/constants/surface';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import NewFieldForm from './NewFieldForm';

export type Props = {
  canDelete: boolean;
  dataType: DataType;
  dataTypes: DataTypes;
  field: DataField;
  onClose: () => void;
  projectName: string;
  refetchData?: () => Promise<any>;
};

const EditFieldModal = ({
  canDelete,
  dataType,
  dataTypes,
  field,
  onClose,
  projectName,
}: Props) => {
  return (
    <NewFieldForm
      key={field.id}
      value={field}
      dataType={dataType}
      onClose={onClose}
      dataTypes={dataTypes}
      projectName={projectName}
      surface={DARK}
      canDelete={canDelete}
    />
  );
};

export default EditFieldModal;
