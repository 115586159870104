import React from 'react';
import {
  IconLink,
  IconMathFunction,
  IconRotateClockwise2,
  IconZoomInArea,
} from '@tabler/icons-react';
import { SelectInput, Surface } from '@noloco/components';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  FORMULA,
  INTEGER,
  LOOKUP,
  MULTIPLE_OPTION,
  OBJECT,
  ROLLUP,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import {
  DUE_DATE,
  OBJECT_FORMATS,
} from '@noloco/core/src/constants/fieldFormats';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { getText } from '@noloco/core/src/utils/lang';
import {
  HIDDEN_DATA_TYPES,
  getGroupedDataTypeOptions,
} from '../../../utils/dataTypes';
import DataFieldIcon from '../../DataFieldIcon';

const iconClassName = 'mr-2 w-5 text-gray-200';

const getOptionItemFromTypeString = (typeString: string) => ({
  value: typeString,
  label: getText('data.types', typeString, 'label'),
  icon: (
    <DataFieldIcon
      field={{ type: typeString } as DataField}
      className={iconClassName}
    />
  ),
});

const AVAILABLE_DATA_TYPES = [
  TEXT,
  DATE,
  INTEGER,
  DECIMAL,
  BOOLEAN,
  SINGLE_OPTION,
  MULTIPLE_OPTION,
  DURATION,
];

const dataTypeFilter = ({ name, internal }: any) =>
  !HIDDEN_DATA_TYPES.includes(name) && !internal;
type Props = {
  value: any;
  dataTypes: any;
  filter?: any;
  lookupFieldsEnabled: boolean;
  objectTypesEnabled: boolean;
  onChange: any;
  refetchData?: any;
  surface: Surface;
  typeOptions?: string[];
  readOnly?: boolean;
};
const FieldTypeInput = ({
  dataTypes,
  filter = dataTypeFilter,
  lookupFieldsEnabled,
  objectTypesEnabled,
  onChange,
  readOnly = false,
  surface,
  value,
  typeOptions,
}: Props) => (
  <SelectInput
    className="mb-2 text-black"
    data-testid="field-type-input"
    contained={true}
    disabled={readOnly}
    options={
      typeOptions
        ? typeOptions.map(getOptionItemFromTypeString)
        : [
            ...AVAILABLE_DATA_TYPES.map(getOptionItemFromTypeString),
            ...(objectTypesEnabled
              ? OBJECT_FORMATS.filter((format) => format !== DUE_DATE).map(
                  (objectFormat) => ({
                    value: objectFormat,
                    label: getText('data.types', OBJECT, objectFormat, 'label'),
                    icon: (
                      <DataFieldIcon
                        field={
                          {
                            type: OBJECT,
                            typeOptions: { format: objectFormat },
                          } as DataField
                        }
                        className={iconClassName}
                      />
                    ),
                  }),
                )
              : []),
            {
              value: FILE,
              label: getText('data.typeInput.fileLabel'),
              icon: (
                <DataFieldIcon
                  field={{ type: FILE } as DataField}
                  className={iconClassName}
                />
              ),
            },
            {
              icon: <IconMathFunction size={16} className={iconClassName} />,
              label: getText('data.typeInput.formula.label'),
              help: getText('data.typeInput.formula.help'),
              value: FORMULA,
            },
            ...(lookupFieldsEnabled
              ? [
                  {
                    icon: (
                      <IconZoomInArea size={16} className={iconClassName} />
                    ),
                    label: getText('data.typeInput.lookup.label'),
                    help: getText('data.typeInput.lookup.help'),
                    value: LOOKUP,
                  },
                ]
              : []),
            {
              icon: (
                <IconRotateClockwise2 size={16} className={iconClassName} />
              ),
              label: getText('data.typeInput.rollup.label'),
              help: getText('data.typeInput.rollup.help'),
              value: ROLLUP,
            },
            {
              icon: <IconLink size={16} className={iconClassName} />,
              label: getText('data.typeInput.relations'),
              options: getGroupedDataTypeOptions(dataTypes, { filter }),
            },
          ]
    }
    placeholder={getText('data.typeInput.placeholder')}
    readOnly={readOnly}
    onChange={onChange}
    surface={surface}
    value={value}
  />
);

export default FieldTypeInput;
