import React, { useMemo, useState } from 'react';
import { Button, FormField } from '@noloco/components';
import SimpleLayout from '@noloco/components/src/components/auth/SimpleLayout';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { BASE_URI } from '../constants/auth';
import { MediaFile } from '../models/File';
import { ProjectSettings } from '../models/Project';
import { isSsoEmailMatchingEnabled } from '../utils/auth';
import { useOpenUrl } from '../utils/hooks/useOpenUrl';
import { getProjectAuthLogo } from '../utils/image';
import { getText } from '../utils/lang';
import PoweredByNoloco from './PoweredByNoloco';

type Props = {
  logo: any;
  media: MediaFile[];
  preview: boolean;
  projectName: string;
  redirectPath: string | null | undefined;
  settings: ProjectSettings;
};

const SamlLogin = ({
  logo,
  media,
  projectName,
  redirectPath,
  preview,
  settings,
}: Props) => {
  const [email, setEmail] = useState('');

  const { src: logoUrl = getProjectAuthLogo(settings, media) } = logo || {};
  const shouldGatherEmail = useMemo(
    () => isSsoEmailMatchingEnabled(settings),
    [settings],
  );

  const { loginTitle, loginSubTitle } = useMemo(() => {
    const loginSettings = settings.login ?? {};

    return {
      loginTitle: loginSettings.title || getText('auth.login.title'),
      loginSubTitle: loginSettings.subTitle || null,
    };
  }, [settings]);
  const baseUrl = useMemo(
    () => `${BASE_URI}/saml/login/${projectName}`,
    [projectName],
  );
  const loginUrl = useMemo(() => {
    const paramObject: Record<string, string> = {};
    if (redirectPath) {
      paramObject.redirect = redirectPath;
    }

    if (shouldGatherEmail) {
      paramObject.email = email;
    }

    const queryString = new URLSearchParams(paramObject).toString();
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  }, [baseUrl, email, redirectPath, shouldGatherEmail]);

  const open = useOpenUrl();

  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-100">
      <SimpleLayout
        logoUrl={logoUrl}
        titleText={loginTitle}
        subTitleText={loginSubTitle}
      >
        <div className="mx-2 mt-6 flex flex-col space-y-4 overflow-hidden rounded-lg bg-white p-7 shadow sm:px-4">
          {shouldGatherEmail && baseUrl && (
            <FormField
              aria-label="email"
              autoComplete="email"
              name="email"
              disabled={preview}
              type="text"
              onChange={({ target: { value } }: any) => setEmail(value)}
              required
              errorType="below-solid"
              label={getText('auth.fields.email')}
              placeholder=""
              value={email}
              surface={LIGHT}
            />
          )}
          {baseUrl && (
            <Button
              className="w-full"
              disabled={preview || (shouldGatherEmail && !email)}
              onClick={() => open(loginUrl, false)}
              variant="primary"
            >
              {getText('auth.login.saml.button')}
            </Button>
          )}
          {!baseUrl && (
            <p className="mx-auto">{getText('errors.genericNoRetry')}</p>
          )}
          <div className="text-center text-sm">
            {getText('auth.login.saml.description')}
          </div>
        </div>
      </SimpleLayout>
      <PoweredByNoloco
        className="mx-auto flex-wrap justify-center text-gray-800"
        projectName={projectName}
        utmSource="noloco_login"
      />
    </div>
  );
};

export default SamlLogin;
